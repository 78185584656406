* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

#__next {
  height: 100vh;
}
